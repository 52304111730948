
import { Component, Vue } from 'vue-property-decorator';

import Form from '@/components/Forms/Form.vue';
import { ContextItemType, CustomContextItem } from 'client-website-ts-library/types/Context';
import { FormConstructorData } from 'client-website-ts-library/types/Forms';
import { Config } from 'client-website-ts-library/services';

@Component({
  components: {
    Form,
  },
})
export default class AppointmentOrReappointmentOfAPropertyAgent extends Vue {
  private formData: FormConstructorData | null = null

  mounted() {
    this.formData = {
      ClientWebsiteId: Config.Website.Id,
      Items: [
        ({
          Type: ContextItemType.CustomData,
          Key: 'PropertyId',
          Value: this.$route.query.PropertyId,
        } as CustomContextItem),
        ({
          Type: ContextItemType.CustomData,
          Key: 'ContactId',
          Value: this.$route.query.ContactId,
        } as CustomContextItem),
      ],
    };
  }
}
